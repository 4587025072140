/* SearchPage.css - Updated styles for results */

.container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .search-form {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  
  .input-group {
    flex-basis: calc(50% - 20px);
    margin-bottom: 20px;
  }
  
  .input-group label {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
  }
  
  .input-group input,
  .input-group button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-group button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .input-group button:hover {
    background-color: #0056b3;
  }
  
  .search-results {
    margin-top: 20px;
  }
  
  .record-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    transition: box-shadow 0.3s ease;
  }
  
  .record-card:hover {
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  
  .record-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .record-info {
    font-size: 16px;
    color: #666;
    margin-top: 5px;
  }
  
  @media screen and (max-width: 600px) {
    .input-group {
      flex-basis: 100%;
    }
  }
  